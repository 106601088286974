/* Font Definitions */
@font-face {
  font-family: Wingdings;
  panose-1: 5 0 0 0 0 0 0 0 0 0;
}
@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 10pt;
  margin-left: 0in;
  line-height: 115%;
  font-size: 14pt;
  font-family: "Calibri", sans-serif;
}
a:link,
span.MsoHyperlink {
  color: blue;
  text-decoration: underline;
}
p.MsoListParagraph,
li.MsoListParagraph,
div.MsoListParagraph {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 10pt;
  margin-left: 0.5in;
  line-height: 115%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
p.MsoListParagraphCxSpFirst,
li.MsoListParagraphCxSpFirst,
div.MsoListParagraphCxSpFirst {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 0.5in;
  line-height: 115%;
  font-size: 14pt;
  font-family: "Calibri", sans-serif;
}
p.MsoListParagraphCxSpMiddle,
li.MsoListParagraphCxSpMiddle,
div.MsoListParagraphCxSpMiddle {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 0.5in;
  line-height: 115%;
  font-size: 14pt;
  font-family: "Calibri", sans-serif;
}
p.MsoListParagraphCxSpLast,
li.MsoListParagraphCxSpLast,
div.MsoListParagraphCxSpLast {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 10pt;
  margin-left: 0.5in;
  line-height: 115%;
  font-size: 14pt;
  font-family: "Calibri", sans-serif;
}
.MsoChpDefault {
  font-family: "Calibri", sans-serif;
}
.MsoPapDefault {
  margin-bottom: 10pt;
  line-height: 115%;
}
@page WordSection1 {
  size: 8.5in 11in;
  margin: 1in 1in 1in 1in;
}
div.WordSection1 {
  page: WordSection1;
}
/* List Definitions */
ol {
  margin-bottom: 0in;
}
ul {
  margin-bottom: 0in;
}
